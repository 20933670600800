




import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import LimitCouponDemo from "@/views/LimitActivities/ActivityDemo/LimitCouponDemo.vue";
@Component({
  components: {
    LimitCouponDemo,
  },
})
export default class LimitCoupon extends Mixins(Mixin) {
  mounted(): void {
    let RedEnvelope = this.$refs["limit-coupon-demo"] as LimitCouponDemo;
    if (RedEnvelope) {
      RedEnvelope.updateData();
    }
  }
}
