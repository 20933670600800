
































































































































import { Component, Mixins } from "vue-property-decorator";
import {
  NavBar,
  Swipe,
  SwipeItem,
  Image,
  Row,
  Toast,
  Popup,
  Dialog,
} from "vant";
import { Mixin } from "@/core/mixins/mixin";
import Ticketltem from "@/components/Tickets/Ticketltem.vue";
import DefaultPopup from "@/views/LimitActivities//components/DefaultPopup.vue";
import AdvertsSwiper from "@/views/LimitActivities//components/AdvertsSwiper.vue";
import LimitSwiper from "@/views/LimitActivities//components/LimitSwiper.vue";
import wx from "weixin-js-sdk";
import CouponDetail from "@/views/Mine/AssetCenter/Coupon/Detail.vue";
import LimitNull from "@/views/LimitActivities/components/LimitNull.vue";
import HisroryNull from "@/views/LimitActivities/components/Fission/HisroryNull.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
    [Row.name]: Row,
    [Popup.name]: Popup,
    [Dialog.Component.name]: Dialog.Component,
    Ticketltem,
    DefaultPopup,
    AdvertsSwiper,
    LimitSwiper,
    CouponDetail,
    LimitNull,
    HisroryNull,
  },
})
export default class LimitCouponDemo extends Mixins(Mixin) {
  showpPrize = false;
  title = "";
  scrollTop = 90;
  advertList: cms.TheaterAdvertDto[] = []; //广告轮播
  activeIndex = 1;
  activeId = "";
  timeList: cms.ActivityLimitCouponTimeDto[] = []; //限时抢券活动时间轴列表
  navHeight = 0;
  isFixed = false;
  showPopup = false;
  couponType = "";
  num = 0;
  id = "";
  activityState = ""; //活动状态
  currentActivity: cms.ActivityLimitCouponDetailDto = {};
  showDetail = false;
  couponId = 0;
  effectTime = "";
  isShowBack = true;
  updateData(): void {
    this.getAllMethods();
    document.addEventListener("visibilitychange", this.refreshPage);
  }
  refreshPage(): void {
    if (document.visibilityState == "visible") {
      let ua = this.getBrowserEnvironment();
      if (ua.ios) {
        this.getAllMethods();
      }
    }
  }
  getAllMethods(): void {
    this.isShowBack = this.$route.query.isTab ? false : true; //是否是首页的tab,是不展示返回按钮
    this.id = this.$route.query.id ? String(this.$route.query.id) : "";
    if (this.$refs.limit && (this.$refs.limit as any).height) {
      this.navHeight = (this.$refs.limit as any).height;
    } else {
      this.navHeight = 0;
    }
    let div = document.getElementById("l-l-coupon") as HTMLElement;
    div.addEventListener("scroll", this.handleScroll);
    this.getSwiperList();
    this.getTimeList();
  }

  beforeDestroy(): void {
    let div = document.getElementById("l-l-coupon") as HTMLElement;
    div.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("visibilitychange", this.refreshPage);
  }
  shareDate(): void {
    let url = `${location.origin}${location.pathname}#/limit-coupon`;
    let title = "优惠券限时抢";
    let shareDescribe = "胜券在握去购票";
    let shareImg = this.currentActivity.headImageUrl || "";
    this.jsBridge(
      "share",
      `title%&@/${title}%&@,desc%&@/${shareDescribe}%&@,picUrl%&@/${shareImg}%&@,url%&@/${url}`
    );
  }

  /*
   * 获取卷列表
   */
  getCouponData(): void {
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    this.$api.cmsApi.flashSale.getLimitCouponActivity(
      {
        activityId: this.activeId,
      },
      ({ data }) => {
        Toast.clear();
        this.currentActivity = data;
        window.document.title = "限时抢券";
        if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          this.jsBridge("isShowShare", "1");
          window.startShare = this.shareDate;
        }
      },
      (error) => {
        if (error.includes("@_@")) {
          let temp = error.split("@_@");
          let code = temp[0];
          if (code == "1129") {
            this.title = "活动已结束";
            this.showpPrize = true;
          } else {
            this.$toast(temp[1]);
          }
        } else {
          error && this.$toast(error);
        }
        Toast.clear();
      }
    );
  }
  /*
   * 限时抢券活动时间轴
   */
  getTimeList(): void {
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    this.$api.cmsApi.flashSale.getLimitCouponActivityTimes(
      ({ data }) => {
        if (data.length > 0) {
          this.timeList = data;
          let result = this.dealTimeList(data); //判断活动状态
          this.activityState = result.activityState;
          this.num = result.num;
          this.activeIndex = result.activeIndex;
          this.activeId = result.activeId;
          Toast.clear();
          this.getCouponData();
        } else {
          this.timeList = [];
          this.currentActivity = {};
          Toast.clear();
        }
      },
      (error) => {
        if (error.includes("@_@")) {
          this.$toast(error.split("@_@")[1]);
        } else {
          error && this.$toast(error);
        }
        Toast.clear();
      }
    );
  }
  confirm(): void {
    this.showpPrize = false;
    this.getTimeList();
  }

  /*
   * 获取轮播列表
   */
  getSwiperList(): void {
    this.$api.cmsApi.shop.findFlagshipStoreAdvert(
      "LIMIT_COUPON",
      ({ data }) => {
        this.advertList = data;
      }
    );
  }

  /*
   * 处理按钮
   */
  dealName(
    status?: string,
    myReceivedCount?: number
  ): {
    status: number;
    name: string;
  } {
    let data = {
      status: 1,
      name: "领取",
    };
    if (status === "2") {
      data.status = 1;
      data.name = "去使用";
    } else if (status === "3") {
      if (myReceivedCount && myReceivedCount > 0) {
        data.status = 1;
        data.name = "去使用";
      } else {
        data.status = 3;
        data.name = "领光";
      }
    } else if (status === "5" || status === "4") {
      data.status = 2;
      data.name = "已过期";
    }
    return data;
  }

  /*
   * 处理按钮下的提示
   rType:receiveQuantityType(领取数量类型，1：不限制，2:每人限领多少张)
   vType:receivedType(领取类型，1可领，2已领，3领光)
   count:myReceivedCount (用户领取数量)
   quantity:receiveQuantity(限制领取数量)
   当rType为1不限制用户领取时，
      如果vType为1  展示用户"持有count张"；如果vType为2 展示用户"持有count张"；如果vType为3 啥也不展示 按钮置灰
  当rType为2时限制用户领取时
  如果vType为1 展示用户最大可领取quantity数量；如果vType为2 展示用户"持有count张/quantity张"；如果vType为3 啥也不展示 按钮置灰
   */
  dealQuantity(
    rType?: number,
    vType?: string,
    count?: number,
    quantity?: number
  ): string {
    let res = "";
    if (rType === 1) {
      //领取
      if (vType === "1") {
        //不限制
        res = count ? `持有${count}张` : "";
      } else if (vType === "2") {
        res = count ? `持有${count}张` : "";
      } else if (vType === "3") {
        res = "";
      }
    } else {
      //使用
      if (vType === "1") {
        if ((count as number) > 0) {
          res = `持有${count}/${quantity}张`;
        } else {
          res = quantity ? `可领取${quantity}张` : "";
        }
      } else if (vType === "2") {
        res = `持有${count}/${quantity}张`;
      } else {
        res = "";
      }
    }
    return res;
  }

  /*
   * 处理价格
   */
  dealPrice(price?: number): string {
    return price ? `${price}` : "";
  }

  /*
   * 订单金额提示
   */
  dealAmount(type?: number, Amount?: number): string {
    let name = "";
    if (type === 1) {
      name = "不限金额";
    } else {
      name = `满${Amount}可用`;
    }
    return name;
  }

  /*
   * 优惠卷详情
   */
  goDetail(couponId?: number, expireTimeStr?: string): void {
    if (couponId) {
      if (this.ChannelModule.channel === "plat_h5") {
        this.couponId = couponId;
        this.showDetail = true;
        this.effectTime = expireTimeStr || "";
      } else {
        this.goCouponDetail(couponId);
      }
    }
  }

  /*
   * 点击领取货使用
   */

  onClick(item: cms.CouponDetailDto): void {
    if (this.activityState === "0") {
      return;
    }
    if (item.receivedType === "1") {
      if (this.activityState === "3") {
        return;
      }
      //领取优惠卷
      this.$api.goodApi.coupon.receiveCoupon(
        item.couponId!,
        { activityId: this.activeId },
        ({ data }) => {
          if (data) {
            this.getCouponData();
            this.$toast("领取成功");
          }
        },
        (error) => {
          if (error.includes("@_@")) {
            let temp = error.split("@_@");
            let code = temp[0];
            if (code == "1129") {
              this.title = "活动已结束";
              this.showpPrize = true;
            }
          } else {
            error && this.$toast(error);
          }
        }
      );
    } else if (item.receivedType === "2") {
      // 去使用
      this.goUse(item);
    } else if (item.receivedType === "3") {
      if (item.myReceivedCount) {
        this.goUse(item);
      }
    }
  }
  goUse(item: cms.CouponDetailDto): void {
    this.couponType = this.getCouponType(
      item.orderAmountType!,
      item.orderAmount!,
      item.parValue!
    );
    let channel = this.ChannelModule.channel;
    let theaterId = this.TheaterModule.theaterId;
    switch (channel) {
      case "plat_h5":
        this.$router.push(
          `/coupons-use?couponId=${item.couponId}&couponType=${this.couponType}`
        );
        break;
      case "plat_ios":
      case "plat_android":
        this.jsBridge(
          "goCouponList",
          `couponId/${item.couponId},couponName/${this.couponType},theaterId/${theaterId}`
        );
        break;
      case "plat_wechat_miniapp":
        wx.miniProgram.navigateTo({
          url: `/pagesIndex/couponsPer/couponsPer?couponId=${item.couponId}&couponLabel=${this.couponType}`,
        });
        break;
      default:
        return;
    }
  }
  getCouponType(
    orderAmountType: number,
    orderAmount: number,
    parValue: number
  ): string {
    let text = "";
    if (orderAmountType === 1) {
      text = `${parValue}元无门槛`;
    } else {
      text = `满${orderAmount}元减${parValue}`;
    }
    return text;
  }

  onClickLeft(): void {
    this.$router.go(-1);
  }
  close(): void {
    this.showPopup = false;
  }
  handleScroll(): void {
    let ob = this.$refs.lMan as HTMLElement;
    this.scrollTop = ob.getBoundingClientRect().top;
    if (this.scrollTop <= 90) {
      this.isFixed = true;
    } else {
      this.isFixed = false;
    }
  }

  /*
   * 点击抢票时间
   */
  activeVolume(data: {
    item: cms.ActivityLimitCouponTimeDto;
    index: number;
  }): void {
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    this.activeId = data.item.activityId!;
    this.activeIndex = data.index;
    this.activityState = data.item.activityState!;
    this.getCouponData();
  }

  /*
   * 去广告详情
   */
  goAdvert(item: cms.TheaterAdvertDto): void {
    this.goToJump(item.keyWord, item.linkId, item.linkType, item.linkUrl);
  }
}
